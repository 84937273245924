// Import necessary functions from vue-router
import { createRouter, createWebHistory } from "vue-router";

// Define all routes
const routes = [
  // General Routes
  {
    path: "/",
    name: "home",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/admin/traffic/Traffic.vue"
      ),
    meta: { title: "Dashboard" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
    meta: { title: "Profile" },
  },
  {
    path: "/ui",
    name: "ui",
    component: () => import(/* webpackChunkName: "ui" */ "@/views/Ui.vue"),
    meta: { title: "UI" },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { title: "Login", fullScreen: true },
  },
  {
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
    meta: { title: "Error", fullScreen: true },
  },

  // Admin Routes
  {
    path: "/purge-caches",
    name: "purge",
    component: () =>
      import(/* webpackChunkName: "purge-caches" */ "@/views/PurgeCaches.vue"),
    meta: { title: "Purge All Caches", fullScreen: false },
  },
  {
    path: "/api-keys",
    name: "apikeys",
    component: () =>
      import(
        /* webpackChunkName: "api-keys" */ "@/views/admin/users/ManageApiKeys.vue"
      ),
    meta: { title: "Manage API Keys", fullScreen: false },
  },
  {
    path: "/send-wa",
    name: "sendwa",
    component: () =>
      import(
        /* webpackChunkName: "send-wa" */ "@/views/admin/whatsapp/Chat.vue"
      ),
    meta: { title: "Send WA Message", fullScreen: false },
  },
  {
    path: "/zesa-admin",
    name: "zesaadmin",
    component: () =>
      import(
        /* webpackChunkName: "zesa-admin" */ "@/views/admin/zesa/ZESA.vue"
      ),
    meta: { title: "ZESA Admin", fullScreen: false },
  },
  {
    path: "/airtime-balance",
    name: "airtimebalance",
    component: () =>
      import(
        /* webpackChunkName: "airtime-balance" */ "@/views/admin/airtime/Bal.vue"
      ),
    meta: { title: "Airtime Balance", fullScreen: false },
  },
  {
    path: "/telone-data",
    name: "telonedata",
    component: () =>
      import(
        /* webpackChunkName: "telone-data" */ "@/views/admin/telone/TelOne.vue"
      ),
    meta: { title: "TelOne ADSL Sales", fullScreen: false },
  },
  {
    path: "/productstocks",
    name: "productstocks",
    component: () =>
      import(
        /* webpackChunkName: "product-stocks" */ "@/views/admin/airtime/Stocks.vue"
      ),
    meta: { title: "Update Product Inventory", fullScreen: false },
  },
  {
    path: "/netone-config",
    name: "netoneconfig",
    component: () =>
      import(
        /* webpackChunkName: "netone-config" */ "@/views/admin/inventories/NetOneConfig.vue"
      ),
    meta: { title: "Update NetOne Inventory", fullScreen: false },
  },
  {
    path: "/usdairtime-config",
    name: "usdairtimeconfig",
    component: () =>
      import(
        /* webpackChunkName: "usd-airtime-config" */ "@/views/admin/inventories/USDAirtimeConfig.vue"
      ),
    meta: { title: "Update USD Airtime Inventory", fullScreen: false },
  },
  {
    path: "/ewz-config",
    name: "ewzconfig",
    component: () =>
      import(
        /* webpackChunkName: "ewz-config" */ "@/views/admin/inventories/EWZConfig.vue"
      ),
    meta: { title: "Update EWZ Inventory", fullScreen: false },
  },
  {
    path: "/zesa-config",
    name: "zesaconfig",
    component: () =>
      import(
        /* webpackChunkName: "zesa-config" */ "@/views/admin/inventories/ZESAConfig.vue"
      ),
    meta: { title: "Update ZESA Inventory", fullScreen: false },
  },

  // Prices Routes
  {
    path: "/update-fuel",
    name: "fuel",
    component: () =>
      import(
        /* webpackChunkName: "fuel-prices" */ "@/views/prices/FuelPrices.vue"
      ),
    meta: { title: "Update Fuel Prices", fullScreen: false },
  },
  {
    path: "/update-lpgas",
    name: "lpgas",
    component: () =>
      import(
        /* webpackChunkName: "lp-gas-prices" */ "@/views/prices/LPGasPrices.vue"
      ),
    meta: { title: "LP Gas Prices", fullScreen: false },
  },
  {
    path: "/update-prices",
    name: "uprices",
    component: () =>
      import(
        /* webpackChunkName: "groceries" */ "@/views/prices/Groceries.vue"
      ),
    meta: { title: "Update Prices", fullScreen: false },
  },
  {
    path: "/update-prices-new",
    name: "upricesnew",
    component: () =>
      import(
        /* webpackChunkName: "groceries-new" */ "@/views/prices/GroceriesNew.vue"
      ),
    meta: { title: "Update Groceries", fullScreen: false },
  },
  {
    path: "/mbare-prices",
    name: "mbare",
    component: () =>
      import(/* webpackChunkName: "mbare-prices" */ "@/views/prices/Mbare.vue"),
    meta: { title: "Update Mbare Prices", fullScreen: false },
  },
  {
    path: "/ecocash",
    name: "ecocash",
    component: () =>
      import(/* webpackChunkName: "ecocash" */ "@/views/prices/Ecocash.vue"),
    meta: { title: "Update Ecocash Tariffs", fullScreen: false },
  },
  {
    path: "/liquid-prices",
    name: "liquid",
    component: () =>
      import(
        /* webpackChunkName: "liquid-prices" */ "@/views/prices/Liquid.vue"
      ),
    meta: { title: "Update Liquid Prices", fullScreen: false },
  },
  {
    path: "/chicken",
    name: "chicken",
    component: () =>
      import(/* webpackChunkName: "chicken" */ "@/views/prices/Chicken.vue"),
    meta: { title: "Update Chicken Prices", fullScreen: false },
  },
  {
    path: "/update-econet-prices",
    name: "econetbundleprices",
    component: () =>
      import(
        /* webpackChunkName: "econet-prices" */ "@/views/prices/Econet.vue"
      ),
    meta: { title: "Update Econet Bundles", fullScreen: false },
  },
  {
    path: "/update-netone-bundles",
    name: "netonebundles",
    component: () =>
      import(
        /* webpackChunkName: "netone-bundles" */ "@/views/prices/NetOne.vue"
      ),
    meta: { title: "Update NetOne Bundles", fullScreen: false },
  },
  {
    path: "/update-telecel-bundles",
    name: "telecelbundles",
    component: () =>
      import(
        /* webpackChunkName: "telecel-bundles" */ "@/views/prices/Telecel.vue"
      ),
    meta: { title: "Update Telecel Bundles", fullScreen: false },
  },
  {
    path: "/zesa-tariffs",
    name: "zesatariffs",
    component: () =>
      import(/* webpackChunkName: "zesa-tariffs" */ "@/views/prices/ZESA.vue"),
    meta: { title: "Update ZESA Tariffs", fullScreen: false },
  },
  {
    path: "/telone-prices",
    name: "telone",
    component: () =>
      import(
        /* webpackChunkName: "telone-prices" */ "@/views/prices/Telone.vue"
      ),
    meta: { title: "Update TelOne Prices", fullScreen: false },
  },
  {
    path: "/utande-prices",
    name: "utande",
    component: () =>
      import(
        /* webpackChunkName: "utande-prices" */ "@/views/prices/Utande.vue"
      ),
    meta: { title: "Update Utande Prices", fullScreen: false },
  },
  {
    path: "/delta-prices",
    name: "delta",
    component: () =>
      import(/* webpackChunkName: "delta-prices" */ "@/views/prices/Delta.vue"),
    meta: { title: "Update Delta Drinks Prices", fullScreen: false },
  },
  {
    path: "/deltaa-prices",
    name: "detala",
    component: () =>
      import(
        /* webpackChunkName: "delta-alcohol-prices" */ "@/views/prices/DeltaA.vue"
      ),
    meta: { title: "Update Delta Alcohol Prices", fullScreen: false },
  },
  {
    path: "/pepsi-prices",
    name: "pepsi",
    component: () =>
      import(/* webpackChunkName: "pepsi-prices" */ "@/views/prices/Pepsi.vue"),
    meta: { title: "Update Pepsi Prices", fullScreen: false },
  },
  {
    path: "/water-prices",
    name: "water",
    component: () =>
      import(/* webpackChunkName: "water-prices" */ "@/views/prices/Water.vue"),
    meta: { title: "Update Water Prices", fullScreen: false },
  },
  {
    path: "/zupco-fares",
    name: "zupco",
    component: () =>
      import(/* webpackChunkName: "zupco-fares" */ "@/views/prices/ZUPCO.vue"),
    meta: { title: "Update ZUPCO Fares", fullScreen: false },
  },
  {
    path: "/license-fees",
    name: "licensefees",
    component: () =>
      import(
        /* webpackChunkName: "license-fees" */ "@/views/prices/LicenseFees.vue"
      ),
    meta: { title: "Update License Fees", fullScreen: false },
  },
  {
    path: "/busfares",
    name: "busfares",
    component: () =>
      import(/* webpackChunkName: "bus-fares" */ "@/views/prices/BusFares.vue"),
    meta: { title: "Update Bus Fares", fullScreen: false },
  },
  {
    path: "/tollgate-fees",
    name: "tollgatefees",
    component: () =>
      import(
        /* webpackChunkName: "tollgate-fees" */ "@/views/prices/TollGates.vue"
      ),
    meta: { title: "Toll Gate Fees", fullScreen: false },
  },

  // Rates Routes
  {
    path: "/update-rates",
    name: "urates",
    component: () =>
      import(
        /* webpackChunkName: "update-rates" */ "@/views/rates/UpdateRates.vue"
      ),
    meta: { title: "Update Rates", fullScreen: false },
  },
  {
    path: "/update-orates",
    name: "uorates",
    component: () =>
      import(
        /* webpackChunkName: "update-official-rates" */ "@/views/rates/UpdateORates.vue"
      ),
    meta: { title: "Update Official Market Rates", fullScreen: false },
  },
  {
    path: "/update-oallrates",
    name: "uoallrates",
    component: () =>
      import(
        /* webpackChunkName: "update-fx-allocations" */ "@/views/rates/UpdateOAll.vue"
      ),
    meta: { title: "Update Official FX Allocations Rates", fullScreen: false },
  },
  {
    path: "/fx-wholesale",
    name: "fxwholesale",
    component: () =>
      import(
        /* webpackChunkName: "fx-wholesale" */ "@/views/rates/WholeSaleAuction.vue"
      ),
    meta: { title: "Wholesale Rates", fullScreen: false },
  },

  // Stats Routes
  {
    path: "/update-inflation",
    name: "inflation",
    component: () =>
      import(/* webpackChunkName: "inflation" */ "@/views/stats/Inflation.vue"),
    meta: { title: "Update Inflation", fullScreen: false },
  },

  // TelOne Routes
  {
    path: "/telone-details",
    name: "telonedetails",
    component: () =>
      import(
        /* webpackChunkName: "telone-details" */ "@/views/telone/CustomerDetails.vue"
      ),
    meta: { title: "TelOne Account Details", fullScreen: false },
  },
  {
    path: "/telone-recharge",
    name: "telonerecharge",
    component: () =>
      import(
        /* webpackChunkName: "telone-recharge" */ "@/views/telone/Recharge.vue"
      ),
    meta: { title: "TelOne Account Recharge", fullScreen: false },
  },

  // Govt Routes
  {
    path: "/govt-fines",
    name: "govtfines",
    component: () =>
      import(/* webpackChunkName: "govt-fines" */ "@/views/govt/Fines.vue"),
    meta: { title: "Update Government Fines", fullScreen: false },
  },
  {
    path: "/births-deaths",
    name: "birthsdeaths",
    component: () =>
      import(
        /* webpackChunkName: "births-deaths" */ "@/views/govt/BirthsDeaths.vue"
      ),
    meta: { title: "Update Birth/Death Fees", fullScreen: false },
  },
  {
    path: "/citizen-status",
    name: "citizenstatus",
    component: () =>
      import(
        /* webpackChunkName: "citizen-status" */ "@/views/govt/CitizenShip.vue"
      ),
    meta: { title: "Update Citizenship Status Fees", fullScreen: false },
  },
  {
    path: "/passport-fees",
    name: "passportfees",
    component: () =>
      import(
        /* webpackChunkName: "passport-fees" */ "@/views/govt/Passports.vue"
      ),
    meta: { title: "Update Passport Fees", fullScreen: false },
  },
  {
    path: "/passport-sadc",
    name: "passportsadc",
    component: () =>
      import(
        /* webpackChunkName: "passport-sadc" */ "@/views/govt/PassportsSADC.vue"
      ),
    meta: { title: "Update Passport Fees", fullScreen: false },
  },
  {
    path: "/zbc-fees",
    name: "zbcfees",
    component: () =>
      import(/* webpackChunkName: "zbc-fees" */ "@/views/govt/ZBCLicence.vue"),
    meta: { title: "Update ZBC Licence", fullScreen: false },
  },
  {
    path: "/cvr-fees",
    name: "cvrfees",
    component: () =>
      import(/* webpackChunkName: "cvr-fees" */ "@/views/govt/CVRLicence.vue"),
    meta: { title: "Update CVR Licence", fullScreen: false },
  },

  // Sales Routes
  {
    path: "/zpc-orders",
    name: "zpcorders",
    component: () =>
      import(/* webpackChunkName: "zpc-orders" */ "@/views/sales/Orders.vue"),
    meta: { title: "Zimpricecheck Sales", fullScreen: false },
  },
  {
    path: "/zpc-order/:order_num?",
    name: "zpcorder",
    component: () =>
      import(/* webpackChunkName: "zpc-order" */ "@/views/sales/Order.vue"),
    meta: { title: "Zimpricecheck View Order", fullScreen: false },
  },
  {
    path: "/sales-charts",
    name: "salescharts",
    component: () =>
      import(
        /* webpackChunkName: "sales-charts" */ "@/views/sales/Analytics.vue"
      ),
    meta: { title: "Sales Analytics", fullScreen: false },
  },

  // Airtime Routes
  {
    path: "/usd-recharge",
    name: "usdrecharge",
    component: () =>
      import(
        /* webpackChunkName: "usd-recharge" */ "@/views/admin/airtime/USDRecharge.vue"
      ),
    meta: { title: "USD Recharge", fullScreen: false },
  },
  {
    path: "/usd-pinless",
    name: "usdpinless",
    component: () =>
      import(
        /* webpackChunkName: "usd-pinless" */ "@/views/admin/airtime/Pinless.vue"
      ),
    meta: { title: "USD Pinless", fullScreen: false },
  },

  // Add any other routes as needed...
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

// Global afterEach hook to set the document title
router.afterEach((to) => {
  const defaultTitle = "Your Default Title"; // Replace with your default title
  document.title = to.meta.title
    ? `${to.meta.title} | ${defaultTitle}`
    : defaultTitle;
});

export default router;
