import {
  mdiAccountCircle,
  mdiDesktopMac,
  mdiTable,
  mdiChartAreaspline,
  mdiTelevisionGuide,
  mdiChartTimelineVariantShimmer,
  mdiChartBellCurveCumulative,
  mdiApplicationEdit,
  mdiTrendingUp,
  mdiGasStation,
  mdiWrenchClock,
  mdiDatabase,
  mdiGasCylinder,
  mdiWhatsapp,
  mdiWifi,
  mdiFruitPineapple,
  mdiWeb,
  mdiPhone,
  mdiCellphone,
  mdiMessageText,
  mdiAccountQuestion,
  mdiAccountArrowLeft,
  mdiFlash,
  mdiLiquor,
  mdiBottleWine,
  mdiGlassMugVariant,
  mdiCup,
  mdiBeerOutline,
  mdiBusSide,
  mdiBusStop,
  mdiOfficeBuildingOutline,
  mdiBusMultiple,
  mdiLightbulbOn,
  mdiGavel,
  mdiStoreCheck,
  mdiCellphoneCheck,
  mdiPanoramaVariantOutline,
  mdiTagMultiple,
  mdiCellphoneMessage,
  mdiBoomGateArrowUpOutline,
  mdiCellphoneArrowDown,
  mdiGoogleAnalytics,
  mdiCashRegister,
  mdiPassport,
  mdiCertificate,
  mdiAccountMultiplePlus,
  mdiCarSide,
  mdiCashFast,
  mdiCart,
  mdiSignal,
  mdiPhoneCheck,
  mdiPower,
  mdiFoodDrumstick,
} from "@mdi/js";

export default [
  "General",
  [
    {
      to: "/",
      icon: mdiDesktopMac,
      label: "Dashboard",
    },
    {
      icon: mdiCashRegister,
      label: "Sales",
      menu: [
        {
          label: "All Orders",
          icon: mdiTrendingUp,
          to: "/zpc-orders",
        },
        {
          label: "Analytics",
          icon: mdiGoogleAnalytics,
          to: "/sales-charts",
        },
      ],
    },
    {
      icon: mdiCart,
      label: "Inventories",
      menu: [
        {
          label: "NetOne Bundles",
          icon: mdiSignal,
          to: "/netone-config",
        },
        {
          label: "USD Airtime",
          icon: mdiPhoneCheck,
          to: "/usdairtime-config",
        },
        {
          label: "EWZ Bundles",
          icon: mdiSignal,
          to: "/ewz-config",
        },
        {
          label: "ZESA Sales",
          icon: mdiPower,
          to: "/zesa-config",
        },
      ],
    },
    {
      icon: mdiWrenchClock,
      label: "Admin",
      menu: [
        {
          label: "Purge Caches",
          icon: mdiDatabase,
          to: "/purge-caches",
        },
        {
          label: "API Keys",
          icon: mdiDatabase,
          to: "/api-keys",
        },
        {
          label: "ZESA Token",
          icon: mdiLightbulbOn,
          to: "/zesa-admin",
        },
        { label: "Send WA Message", icon: mdiMessageText, to: "/send-wa" },

        {
          label: "USD Recharge",
          icon: mdiCellphone,
          to: "/usd-recharge",
        },
        {
          label: "USD Pinless",
          icon: mdiCellphoneArrowDown,
          to: "/usd-pinless",
        },
        {
          label: "Airtime Balance",
          icon: mdiCellphoneCheck,
          to: "/airtime-balance",
        },
        {
          label: "Product Inventory",
          icon: mdiStoreCheck,
          to: "/productstocks",
        },
        {
          label: "TelOne Data",
          icon: mdiWifi,
          to: "/telone-data",
        },
      ],
    },
  ],
  "Site Stats",
  [
    {
      label: "Exchange Rates",
      subLabel: "Submenus Example",
      icon: mdiChartAreaspline,
      menu: [
        {
          label: "Update Rates",
          icon: mdiApplicationEdit,
          to: "/update-rates",
        },
        {
          label: "Wholesale Rates",
          icon: mdiApplicationEdit,
          to: "/fx-wholesale",
        },
        {
          label: "Official Summaries",
          icon: mdiChartTimelineVariantShimmer,
          to: "/update-orates",
        },
        {
          label: "Official Allocations",
          icon: mdiChartBellCurveCumulative,
          to: "/update-oallrates",
        },
      ],
    },
  ],
  "Price Updates",
  [
    {
      label: "Price Updates",
      subLabel: "Price Updates",
      icon: mdiTagMultiple,
      menu: [
        {
          label: "Update Fuel Prices",
          icon: mdiGasStation,
          to: "/update-fuel",
        },
        {
          label: "Update Gas Prices",
          icon: mdiGasCylinder,
          to: "/update-lpgas",
        },
        {
          label: "Update Chicken Prices",
          icon: mdiFoodDrumstick,
          to: "/chicken",
        },

        {
          label: "Update Grocery Prices",
          icon: mdiCart,
          to: "/update-prices-new",
        },
        {
          label: "Mbare Prices",
          icon: mdiFruitPineapple,
          to: "/mbare-prices",
        },
        {
          label: "Liquid Home Prices",
          icon: mdiWeb,
          to: "/liquid-prices",
        },
        {
          label: "TelOne Prices",
          icon: mdiWifi,
          to: "/telone-prices",
        },
        {
          label: "Utande Prices",
          icon: mdiWifi,
          to: "/utande-prices",
        },
        {
          label: "ZESA Tariffs",
          icon: mdiFlash,
          to: "/zesa-tariffs",
        },
        {
          label: "Ecocash Tariffs",
          icon: mdiCashFast,
          to: "/ecocash",
        },
      ],
    },
  ],
  "Data,SMS,Voice Bundles",
  [
    {
      label: "Mobile Data",
      subLabel: "Price Updates",
      icon: mdiCellphoneMessage,
      menu: [
        {
          label: "Update Econet Bundle Prices",
          icon: mdiWhatsapp,
          to: "/update-econet-prices",
        },
        {
          label: "NetOne Bundles",
          icon: mdiWhatsapp,
          to: "/update-netone-bundles",
        },
        {
          label: "Telecel Bundles",
          icon: mdiWhatsapp,
          to: "/update-telecel-bundles",
        },
      ],
    },
  ],
  "Drink Prices",
  [
    {
      label: "Water and Drinks",
      subLabel: "Drink Prices",
      icon: mdiLiquor,
      menu: [
        {
          label: "Delta Drinks",
          icon: mdiBottleWine,
          to: "/delta-prices",
        },
        {
          label: "Delta Alcohol Prices",
          icon: mdiGlassMugVariant,
          to: "/deltaa-prices",
        },
        {
          label: "Pepsi Prices",
          icon: mdiCup,
          to: "/pepsi-prices",
        },
        {
          label: "Water Prices",
          icon: mdiBeerOutline,
          to: "/water-prices",
        },
      ],
    },
  ],
  "Transport",
  [
    {
      label: "Transport Items",
      subLabel: "Fares, tollgate fees,license fees",
      icon: mdiBusStop,
      menu: [
        {
          label: "ZUPCO",
          icon: mdiBusSide,
          to: "/zupco-fares",
        },
        {
          label: "Bus Fares",
          icon: mdiBusMultiple,
          to: "/busfares",
        },
        {
          label: "License Fees",
          icon: mdiOfficeBuildingOutline,
          to: "/license-fees",
        },
        {
          label: "Toll Gate Fees",
          icon: mdiBoomGateArrowUpOutline,
          to: "/tollgate-fees",
        },
      ],
    },
  ],
  "TelOne Recharge",
  [
    {
      label: "TelOne Recharge",
      subLabel: "TelOne Recharge",
      icon: mdiPhone,
      menu: [
        {
          label: "Customer Details",
          icon: mdiAccountQuestion,
          to: "/telone-details",
        },
        {
          label: "Recharge TelOne",
          icon: mdiAccountArrowLeft,
          to: "/telone-recharge",
        },
      ],
    },
  ],
  "Stats & Govt",
  [
    {
      label: "Update Stats & Govt",
      icon: mdiTable,
      updateMark: true,
      menu: [
        {
          label: "Update Inflation",
          icon: mdiTrendingUp,
          to: "/update-inflation",
        },
        {
          label: "Update Fines",
          icon: mdiGavel,
          to: "/govt-fines",
        },
        {
          label: "Update Passport Fees",
          icon: mdiPassport,
          to: "/passport-fees",
        },
        {
          label: "Update SADC Passport Fees",
          icon: mdiPassport,
          to: "/passport-sadc",
        },
        {
          label: "Update Birth/Death Fees",
          icon: mdiCertificate,
          to: "/births-deaths",
        },
        {
          label: "Update Citizenship Fees",
          icon: mdiAccountMultiplePlus,
          to: "/citizen-status",
        },

        {
          label: "Update ZBC Licence Fees",
          icon: mdiAccountMultiplePlus,
          to: "/zbc-fees",
        },
        {
          label: "Update CVR Fines",
          icon: mdiCarSide,
          to: "/cvr-fees",
        },
      ],
    },
    {
      to: "/ui",
      label: "UI",
      icon: mdiTelevisionGuide,
    },

    {
      to: "/profile",
      label: "Profile",
      icon: mdiAccountCircle,
    } /*
    {
      to: "/error",
      label: "Error",
      icon: mdiAlertCircle,
    },*/,
  ],
];
